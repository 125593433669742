import assets from "../assets/assets";

const data = {
    coatings: [
        {
            'name': 'Interior',
            'desc_corta':'Trabajo realizado con marmol crema marfil',
            'desc_larga': 'Revestimiento interno de *mármol* color Crema Marfil. Cuidamos el brillo propio del material *sin realizar un pulido*, cada baldosa es colocada y nivelada a la perfección, *a mano*, pudiendo pasar una hoja de afeitar en cada transición *sin* encontrar *relieve alguno*.\n\n _Instalacion:_\n 1. *Tratado de la zona*, consta de un barrido de una esponja húmeda.\n2. *Centrado* (centro x centro).\n3. *Colocación* de Klaukol, cal reforzada y como puente de adherencia entre éstos y la pieza a colocar klaukol de nuevo, asegurando una conexión robusta y duradera.\n4. *Transición* entre habitaciones.\n\n * Ante cualquier consulta sobre materiales, forma de adherencia o superficies, escribanos, estamos para asesorarlo.*',
            'portada': "/images/Interiores/portada.jpg",
            'tiempo':'1 Día x habitación',
            'ubicacion':'Miami-USA',
            'recuadre':'CentroxCentro, Nivelacion externa',
            'demas_fotos':["/images/Interiores/portada.jpg","/images/Interiores/proceso.jpg","/images/Interiores/detalle 2.jpg",
            "/images/Interiores/t1 (5).jpg","/images/Interiores/t1 (8).jpg","/images/Interiores/t1 (13).jpg",],
             "desc1":'El centrado se realiza en función de 2 paredes (centro x centro), luego nivelamos con respecto al suelo del exterior, de esta manera toda la casa queda a la misma altura. Luego las uniones entre habitaciones se realizan debajo de la puerta, de esta manera de un lado u otro siempre se ve la perfección.',
             "desc2":'Avanzamos desde el centro de la habitación dejando para el final las uniones con la pared, ya que aquí hay que cortar las piezas, si las piezas completas ya están colocadas, se logra un mejor acabado al cortar a la medida precisa. Además mientras la obra avanza recibimos las secciones que son terminadas para cuidar la integridad del material y evitar rayones que arruinan estas obras fantásticas.',
             "desc3":'Esta sección es la máxima representación de la calidad de nuestro trabajo. El círculo con la estrella que se ve consta de una sola pieza hecha a medida, por otro lado se puede observar que esta está colocada sobre la intersección de 4 baldosas, las cuales hábilmente cortamos tomando la medida precisa del círculo estrellado, es tan preciso este corte que no puede distinguirse si el círculo pertenece a las baldosas o si es una pieza individual.',
             "imgdesc1":'/images/Interiores/origenes.jpg',
             "imgdesc2":'/images/Interiores/proteccion.jpg',
             "imgdesc3":'/images/Interiores/portada 2.2.jpg'
        },
        {
            'name': 'Detallado',
            'desc_corta':'Preguntarle a Pablo el material',
            'desc_larga': `_Caracteristicas:_\nBaldosas rectangulares de 12x6 pulgadas, cuadrados internos de 3x3. Colocado *sin pulir* para lograr un acabado más estético, cuidando el material, y logrando un acabado sumamente suave tan solo con nuestras *expertas manos.* Estas habitaciones en su totalidad se lograron en tan solo 10 días. \n\n _Instalacion:_\n 1. *Tratado de la zona*, consta de un barrido de una esponja húmeda.\n2. *Centrado* (centro x centro).\n3. *Colocación* de Klaukol, cal reforzada y como puente de adherencia entre éstos y la pieza a colocar klaukol de nuevo, asegurando una conexión robusta y duradera.\n4. *Transición* entre habitaciones.\n\n * Ante cualquier consulta sobre materiales, forma de adherencia o superficies, escribanos, estamos para asesorarlo.*`,
            'portada': "/images/Detallado/portada.jpg",
            'tiempo':'10 Días',
            'ubicacion':'Miami-USA',
            'recuadre':'CentroxCentro, Nivelacion externa',
            'demas_fotos':["/images/Detallado/proceso.jpg","/images/Detallado/proceso 1.jpg","/images/Detallado/detalle 2.jpg"],
            "desc1":'El proceso de instalación consta de colocar todas las baldosas rectangulares, cuidando milimétricamente las uniones ya que al terminar con este proceso, quedan los espacios de 3x3” donde calzan perfectamente los cuadrados de 3x3”, el más mínimo error haría que estos no entren o quedarán uniones toscas. Este proceso no solo nos otorga un acabado más prolijo y más suave si no más eficiente en la colocación. ',
            "desc2":'',
            "desc3":'',
            "imgdesc1":'/images/Detallado/proceso.jpg',
            "imgdesc2":'',
            "imgdesc3":''
        },
        {
            'name': 'Exterior',
            'desc_corta':'Trabajo realizado con baldosas atermicas',            
            'desc_larga': 'Sumérgete en la innovación y diseño de nuestro revestimiento de suelo atermico, una obra maestra que va más allá de la estética convencional. La colocación meticulosa en las escaleras redefine la experiencia de transitar, donde cada paso es firme y seguro.\nPero no nos detenemos allí. Las columnas cilíndricas, elementos arquitectónicos clave, se transforman en esculturas de diseño. Comenzamos con una columna metálica pintada con producto epoxi, seguida de la aplicación estratégica de arena para una adhesión óptima. Luego, la columna o el molde se colocan sobre el epoxi y se rellenan con cemento blanco, creando un contraste visualmente impactante.\nLa instalación en toda la obra del revestimiento consta del mismo material, así que el método de adhesión descrito a continuación puede ser extendido; este consta de un barrido y luego se humedece la superficie luego  utilizando Klaukol, sobre este cal reforzada y por último como puente de adherencia  repetimos el Klaukol, esto asegura una conexión duradera y firme en el tiempo. \nCada detalle, desde la colocación de las baldosas hasta la creación de columnas, se realiza con maestría. Todo se nivelado y recuadrado para garantizar una cohesión estética perfecta. Este revestimiento de suelo atérmico no solo ofrece funcionalidad y seguridad, sino que también se convierte en una expresión de arte y diseño en cada rincón de tu espacio. Sumérgete en la belleza funcional, donde cada elemento cuenta su propia historia visual.',
            'portada': "/images/Exteriores/Portada.jpg",
            'tiempo':'8 Días',
            'ubicacion':'Miami-USA',
            'recuadre':'CentroxCentro, Nivelacion externa',
            'demas_fotos':["/images/Exteriores/Portada.jpg","/images/Exteriores/Comienzo.jpg",
            "/images/Exteriores/escalera.jpg","/images/Exteriores/Finiquitado.jpg","/images/Exteriores/proceso.jpg",
            "/images/Exteriores/general.jpg"],
            "desc1":'En las escaleras, adoptamos un enfoque cuidadoso: primero la colocación de las baldosas de la pedada, seguida de una nivelación precisa que garantiza una superficie uniforme y cómoda. El frentin, agregado después, se convierte en un detalle que resalta la elegancia de cada escalón. Luego las uniones son llenadas y selladas utilizando cemento blanco.',
            "desc2":'La instalación comienza centro x centro, cada baldosa se coloca con precisión en el corazón del ambiente, asegurando una disposición simétrica que evaluaremos en conjunto con la pedada de las escaleras, logrando así la mayor comodidad al transitar el espacio.',
            "desc3":'Esta prolijidad descrita tanto en las escaleras, en el suelo y en las columnas, es respetado incluso  en estos balcones individuales de cada ventanal, que tienen no solo un borde semi curvo si no un interior a 45° que en conjunto resaltan la elegancia de la obra. La nivelación de estas secciones es clave ya que al momento de nivelar los pisos internos de la casa, es el exterior el que sirve de punto de partida y referencia.',
            "imgdesc1":'/images/Exteriores/escalera trasera.jpg',
            "imgdesc2":'/images/Exteriores/Comienzo.jpg',
            "imgdesc3":'/images/Exteriores/Finiquitado.jpg'
        },
        {
            'name': 'Baños',
            'desc_corta':'Trabajo realizado con en marmol y simil madera',            
            'desc_larga': 'Bienvenidos a la majestuosidad de nuestros baños trabajados sobre *mármol, y porcelanato*.\n Estas obras inmensas representan a la *perfección* nuestra *calificada mano* para trabajar materiales delicados y lograr acabados increíbles.\n En estos trabajos tenemos la inclusión de distintas características para la instalación, *detalladas* en otras publicaciones, como lo son el *tratado de superficies* antes de la adherencia, la *diversidad de materiales* a colocar, lo que conlleva a una *gran variedad* de productos para la *adherencia*, técnicas como el *ingletado* en *mármol* y porcelanato, que aseguran una conexión *sólida* y *protege* la integridad del *material*, entre otras. \n\n Si busca conocer *más detalles* o quiere *consultarnos* por materiales tanto de adherencia como de revestimiento, o metodos utilizados, *¡escribamos al wsp las 24hs del dia!*, en *OCC* siempre compartimos nuestro *conocimiento.*',
            'portada': "/baños/baño-1.mp4",
            'tiempo':'El necesario',
            'ubicacion':'Miami-USA',
            'recuadre':'CentroxCentro, Nivelacion externa',
            'demas_fotos':['/baños/baño-1.mp4' ,'/baños/baño-2.mp4' ,'/baños/baño-3.mp4' ,'/baños/baño-4.mp4'],
            "desc1":'',
            "desc2":'',
            "desc3":'',
            "imgdesc1":'https://lh3.googleusercontent.com/pw/AP1GczMAFUBhxkNDWYxNAAQ8yxErl_wWMRb2wmRq13HRFQ6vTaetq4Q6gkxYaCXrvoQDAIYFnyC3kLGEQ3AfEy1cx5VXxfuIQH1M_5U5aZjPUNNs30SZj9WDTdDawL0Q2WWeIGaGzjK0nkNj-mtdCECGqaFN8g=w1140-h641-s-no-gm',
            "imgdesc2":'https://lh3.googleusercontent.com/pw/AP1GczOrocqYdyv7dJvORrE5q39t0GmDtLDuxNUgfhfvaALRmQdVQXH5mDewXwYe88CDDk9OfQug7fbJEmWux9ON5dIurlTW2EqFacsgkwo1EU0GIybHuYy6Gome_XYfcV1xyvQl8QVQb73q0EjqXLfXsOtsbA=w1140-h641-s-no-gm',
            "imgdesc3":'',
        },
        {
            'name': 'Interior 3',
            'desc_corta':'Trabajo realizado en porcelanato',
            'desc_larga': 'Revestimiento en planta baja de porcelanato. Cuidamos el brillo propio del material *sin realizar un pulido*, cada baldosa es colocada y nivelada a la perfección, puede observarse el trabajo minucioso para nivelar cada baldosa entre sí. \n\n _ Instalación:_\n Tratado de la zona, consta de un barrido de una esponja húmeda.\nColocación de Klaukol, cal reforzada y como puente de adherencia entre éstos y la pieza a colocar klaukol de nuevo, asegurando una conexión robusta y duradera.\nCentrado (centro x centro).\nTransición entre habitaciones.\n\n * Ante cualquier consulta sobre materiales, forma de adherencia o superficies, escribanos, estamos para asesorarlos.*',
            'portada': "/images/Interior 2/portada.jpg",
            'tiempo':'1/2 Día x habitación',
            'ubicacion':'Sta. Lucía - Pilar',
            'recuadre':'CentroxCentro, Nivelacion externa',
            'demas_fotos':["/images/Interior 2/barrido.jpg","/images/Interior 2/sellado.jpg","/images/Interior 2/sellado listo.jpg",
            "/images/Interior 2/procedimiento (11).jpg","/images/Interior 2/procedimiento (6).jpg","/images/Interior 2/acabado (3).jpg",
            "/images/Interior 2/acabado (4).jpg","/images/Interior 2/acabado (2).jpg","/images/Interior 2/acabado (1).jpg",],
             "desc1":'En estas 3 imágenes podemos observar cómo  se realiza el corte para lograr una sección recta, que se adapte de la manera más precisa al suelo donde se coloca el revestimiento. ',
             "desc2":'',
             "desc3":'',
             "imgdesc1":"/images/Interior 2/corte.png",
             "imgdesc2":'',
             "imgdesc3":''
        },
        {
            'name': 'Interior 2',
            'desc_corta':'Trabajo realizado con porcelanato',
            'desc_larga': 'Revestimiento en planta baja de porcelanato. Cuidamos el brillo propio del material *sin realizar un pulido*, cada baldosa es colocada y nivelada a la perfección, puede observarse el trabajo minucioso para nivelar cada baldosa entre sí. \n\n _ Instalación:_\n Tratado de la zona, consta de un barrido de una esponja húmeda.\nColocación de Klaukol, cal reforzada y como puente de adherencia entre éstos y la pieza a colocar klaukol de nuevo, asegurando una conexión robusta y duradera.\nCentrado (centro x centro).\nTransición entre habitaciones.\n\n * Ante cualquier consulta sobre materiales, forma de adherencia o superficies, escribanos, estamos para asesorarlos.*',
            'portada': "/images/Interior 3/portada.jpg",
            'tiempo':'1/2 dia por ambiente',
            'ubicacion':'Sta. Lucía - Pilar',
            'recuadre':'CentroxCentro, Nivelacion externa',
            'demas_fotos':["/images/Interior 3/inicio.jpg","/images/Interior 3/inicio2.jpg","/images/Interior 3/proceso (1).jpg",
            "/images/Interior 3/proceso (2).jpg","/images/Interior 3/proceso (3).jpg","/images/Interior 3/brillo (1).jpg",
            "/images/Interior 3/brillo (2).jpg","/images/Interior 3/brillo (5).jpg","/images/Interior 3/brillo.jpg"],
             "desc1":'A simple vista podría parecer un fallo en la terminación, pero este pequeño espacio de menos de 5 mm se deja de manera intencional entre el material colocado y la pared, de esta manera el asentamiento y el movimiento propio del material por dilatacion y contraccion, trabaja independiente del material en la pared, cuidando la integridad de ambos.',
             "desc2":'El acabado que aseguramos en la nivelación es tan preciso, que podrías pasar una hoja de afeitar entre cada unión sin notar ningún relieve molesto al tacto. Además cuidamos el brillo natural del material, sin pulir, pero cuidando a cada momento la pieza, queda como si de un espejo se tratase.',
             "desc3":'En esta habitación no comenzamos centro x centro como es de costumbre, si no desde el punto crítico del espacio, donde confluyen 2 ambientes y un pasillo, de esta manera todo el espacio interconectado quedará de la manera más prolija, porque en OCC no solo aseguramos suavidad, conexión sólida, duradera y brillosa, si no la adaptación para conseguir el acabado más prolijo.',
             "imgdesc1":"/images/Interior 3/espaciado.jpg",
             "imgdesc2":'/images/Interior 3/hoja.jpg',
             "imgdesc3":'/images/Interior 3/inicio.jpg'
        },
    ]
};

export default data;