import '../styles/App.css'
import assets from '../assets/assets'
import { Home } from './Home'

export function Body (){
    return(

        <Home/>

    )

}