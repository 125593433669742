import assets from "../assets/assets";

const dataMasTrabajos = {
    pools: [
        {
            'name': 'Florida, USA',
            'desc_corta':'4000 pies cuadrados revestidos en venecitas biseladas. Con secciones en mármol y porcelanato ingletado',
            'portada':'/images/trabajos-en-piscinas/fuente-revestida-con-marmol-porcelanato-y-venecitas.webp',
            'modal': "/images/trabajos-en-piscinas/fuente-recubierta-con-marmol-porcelanato-y-venecitas.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Colorado, USA',
            'desc_corta':'Revestimiento realizado con venecitas biseladas en jacuzzi y piscina, combinado con un borde en mármol.',
            'portada': '/images/trabajos-en-piscinas/pileta-con-jacuzzi-revesitda-en-porcelanato-y-venecitas.webp',
            'modal':"/images/trabajos-en-piscinas/pileta-con-jacuzzi-revesitda-en-porcelanato-y-venecitas-azules.webp",
            'año': '2019',
            'mes': 'SEP',
        },
        {
            'name': 'Naples, USA',
            'desc_corta':'Piscina con jacuzzi revestidos con venecitas biseladas, celestes y azules.',        
            'portada': "/images/trabajos-en-piscinas/pileta-con-jacuzzi-revestida-con-venecitas-azules-y-violetas.webp",
            'modal':'/images/trabajos-en-piscinas/piscina-con-jacuzzi-revestida-con-venecitas-azules-y-violetas.webp',
            'año': '2018',
            'mes': 'ENE',
        },
        {
            'name': 'Miami, USA',
            'desc_corta':'1500 pies cuadrados, revestidos con venecitas biseladas, un borde atermico de 1x1 pie, y un borde en mármol para el jacuzzi.',
            'portada': "/images/trabajos-en-piscinas/pileta-con-jacuzzi-revestida-en-venecitas-blancas-y-celestes.webp",
            'modal':'/images/trabajos-en-piscinas/pileta-con-jacuzzi-revestida-en-venecitas-blancas-y-celestes-colage.webp',
            'año': '2018',
            'mes': 'DIC',
        },
        {
            'name': 'Colorado, USA',
            'desc_corta':'1100 pies cuadrados (piscina + jacuzzi), revestidos con venecitas crema, azul oscuro y mármol.".',
            'portada': "/images/trabajos-en-piscinas/pileta-con-jacuzzi-revestida-en-venecitas-crema-y-negras.webp",
            'modal':"/images/trabajos-en-piscinas/pileta-con-jacuzzi-revestida-en-venecitas-crema-y-negras-colage.webp",
            'año': '2019',
            'mes': 'FEB',
        },
        {
            'name': 'Naples, USA',
            'desc_corta':'Sin secciones rectas, completamente revestida en venectias biseladas color azul (sup de 680 pies2), con un borde atermico de 12x4 pulgadas.',
            'portada': "/images/trabajos-en-piscinas/pileta-curva-revestida-en-venecitas-azules.webp",
            'modal':'/images/trabajos-en-piscinas/pileta-curva-revestida-en-venecitas-azules-con-destellos-blancos.webp',
            'año': '2020',
            'mes': 'JUL',
        },
        {
            'name': 'Pilar, ARG',
            'desc_corta':'Revestimiento realiado en venecitas biseladascolor turquesa, con un patron espiralado en blanco y azul.',
            'portada': "/images/trabajos-en-piscinas/pileta-revesitda-en-venecitas-celestes-con-patron-espiralado.webp",
            'modal':'/images/trabajos-en-piscinas/pileta-revesitda-en-venecitas-celestes-con-patron-espiralado-con-borde-atermico.webp',
            'año': '2023',
            'mes': 'JUL',
        },
        {
            'name': 'Colorado Spring, USA',
            'desc_corta':'Venecitas biseladas. Color turquesa combinadas con azul.',
            'portada': "/images/trabajos-en-piscinas/pileta-revesitda-en-venecitas-celestes-y-blancas.webp",
            'modal':'/images/trabajos-en-piscinas/pileta-revesitda-en-venecitas-celestes-con-detalles-en-azul.webp',
            'año': '2022',
            'mes': 'MAY',
        },
        {
            'name': 'Miami, USA',
            'desc_corta':'720 pies cuadrados, revestidos en venecitas biseladas azules, con un borde atermico de 12x12 pulgadas.',
            'portada':'/images/trabajos-en-piscinas/pileta-revestida-con-venecitas-azules.webp',
            'modal': "/images/trabajos-en-piscinas/pileta-revestida-con-venecitas-azules-y-borde-atermico.webp",
            'año': '2019',
            'mes': 'SEP',
        },
        {
            'name': 'Orlando florida, USA',
            'desc_corta':'680 pies cuadrados revestidos en venecitas azules. Con un borde atermico clasico.',
            'portada': "/images/trabajos-en-piscinas/pileta-revestida-con-venecitas-biseladas-azules.webp",
            'modal': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-biseladas-azules.webp",
            'año': '2021',
            'mes': 'JUN',
        },
        {
            'name': 'Miami, USA',
            'desc_corta':'800 pies cuadrados, revestidos en venecitas biseladas color azul/morado. Con un borde atermico de 12x4 pulgadas.',
            'portada': "/images/trabajos-en-piscinas/pileta-revestida-con-venecitas-violetas.webp",
            'modal': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-violetas.webp",
            'año': '2022',
            'mes': 'AGO',
        },
        {
            'name': 'Naples, USA',
            'desc_corta':'Venecitas biseladas, gama de pigmentos celestes. Con borde atermico de 2x2 pies.',
            'portada': "/images/trabajos-en-piscinas/piscina-con-cascada-revestida-con-venecitas-celestes.webp",
            'modal': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-celestes.webp",
            'año': '2021',
            'mes': 'OCT',
        },
        {
            'name': 'Colorado, USA',
            'desc_corta':'Piscina con jacuzzi revestida en venecitas biseladas celestes, con un borde atermico de 12x4 pulgadas.',
            'portada': "/images/trabajos-en-piscinas/piscina-con-jacuzzi-revestida-con-venecitas-celestes (1).webp",
            'modal': "/images/trabajos-en-piscinas/piscina-con-jacuzzi-revestida-con-venecitas-celestes.webp",
            'año': '2020',
            'mes': 'JUN',
        },
        {
            'name': 'Florida, USA',
            'desc_corta':'Venecitas biseladas color turquesa. Con borde atermico de 4x12 pulgadas.',
            'portada': "/images/trabajos-en-piscinas/piscina-recubierta-con-venecitas-azules (1).webp",
            'modal': "/images/trabajos-en-piscinas/pisicna-rectangular-revestida-con-venecitas-azules.webp",
            'año': '2022',
            'mes': 'ENE',
        },
        {
            'name': 'Miami, USA',
            'desc_corta':'Revestimiento realizado con venecitas biseladas azules, con acabados en blanco.',
            'portada': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-azules (1).webp",
            'modal': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-azules.webp",
            'año': '2021',
            'mes': 'SEP',
        },
        {
            'name': 'Naples, USA',
            'desc_corta':'Revestimiento de 650 pies cuadrados en venecitas biseladas, mas un borde atermico de 2x1 pie.',
            'portada': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-azules-con-borde-atermico.webp",
            'modal': "/images/trabajos-en-piscinas/piscina-revestida-con-venecitas-azules-con-borde-atermico-collage.webp",
            'año': '2019',
            'mes': 'SEP',
        }
    ],
    marmol: [
        {
            'name': 'Naples, USA',
            'desc_corta':'Baño revestido en mármol mormoleado naranja.',
            'portada': "/images/trabajos-en-marmol/baño-revestido-en-marmol-amarillento.webp",
            'cara': "/images/trabajos-en-marmol/baño-en-marmol-amarillento.webp",
            'año': '2018',
            'mes': 'ENE',
        },
        {
            'name': 'Colorado, USA',
            'desc_corta':'Baño de dos cuerpos revestido en mármol marmoleado oscuro.',
            'portada': "/images/trabajos-en-marmol/baño-recubierto-en-marmol-negro-marmoleado.webp",
            'cara': "/images/trabajos-en-marmol/baños-revestido-en-marmol-negro-marmoleado.webp",
            'año': '2019',
            'mes': 'JUN',
        },
        {
            'name': 'Colorado, USA',
            'desc_corta':'Baño revestido en Mármol naranja.',
            'portada': "/images/trabajos-en-marmol/baño-revestido-en-marmol-naranja-detallado.webp",
            'cara': "/images/trabajos-en-marmol/baño-revestido-en-marmol-naranja.webp",
            'año': '2020',
            'mes': 'FEB',
        },
        {
            'name': 'Miami, USA',
            'desc_corta':'Pisos de Mármol con instalacion de piezas en porcelanato.',
            'portada': "/images/trabajos-en-marmol/cortes-en-marmol-precisos-detallado.webp",
            'cara': "/images/trabajos-en-marmol/cortes-en-marmol-precisos.webp",
            'año': '2022',
            'mes': 'DIC',
        },
        {
            'name': 'Naples, USA',
            'desc_corta':'Pisos revestidos en Mármol.',
            'portada': "/images/trabajos-en-marmol/piso-de-marmol-amarillo-con-candelabro.webp",
            'cara': "/images/trabajos-en-marmol/piso-de-marmol-con-candelabro.webp",
            'año': '2021',
            'mes': 'SEP',
        },
        {
            'name': 'Colorado Spring, USA',
            'desc_corta':'Interior revestido en Mármol Blanco',
            'portada': "/images/trabajos-en-marmol/piso-en-marmol-blanco-grisaseo.webp",
            'cara': "/images/trabajos-en-marmol/piso-en-marmol-blanco.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Naple, USA',
            'desc_corta':'Mármol en pisos de exterior.',
            'portada': "/images/trabajos-en-marmol/piso-exterior-revestido-en-marmol-gris-y-marron.webp",
            'cara': "/images/trabajos-en-marmol/piso-exterior-revestido-en-marmol.webp",
            'año': '2019',
            'mes': 'JUL',
        },
        {
            'name': 'Miami, USA',
            'desc_corta':'Pisos revestidos en Mármol',
            'portada': "/images/trabajos-en-marmol/piso-revestido-en-marmol-blanco-anaranjado.webp",
            'cara': "/images/trabajos-en-marmol/piso-recubierto-con-baldosas-de-marmol-blanco.webp",
            'año': '2018',
            'mes': 'AGO',
        },
        {
            'name': 'Florida, USA',
            'desc_corta':'Mármol anaranjado veteado.',
            'portada': "/images/trabajos-en-marmol/pisos-revestidos-en-marmol-blanco-anaranjado.webp",
            'cara': "/images/trabajos-en-marmol/piso-recubierto-en-marmol-naranja.webp",
            'año': '2023',
            'mes': 'ENE',
        }
    ],
    pisos: [
        {
            'name': 'Piso en porcelanato',
            'desc_corta':'Piso en interior trabajado en porcelanato',
            'portada': "/images/trabajos-en-porcelanato/baño-revestido-en-porcelanato-blanco-detallado.webp",
            'cara': "/images/trabajos-en-porcelanato/baño-revestido-en-porcelanato-blanco.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Clicker nivelador',
            'desc_corta':'Trabajo en interior con sus correspondientes clicker para nivelacion',
            'portada': "/images/trabajos-en-porcelanato/baño-revestido-en-porcelanato-naranja-zom.webp",
            'cara': "/images/trabajos-en-porcelanato/baño-revestido-en-porcelanato-naranja.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Porcelanato Atermico',
            'desc_corta':'Trabajo de porcelanato atermico en exterior',
            'portada': "/images/trabajos-en-porcelanato/fuente-revestida-en-porcelanato-negro-y-amarillo.webp",
            'cara': "/images/trabajos-en-porcelanato/fuente-revestida-en-porcelanato-negro.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Porcelanato simil madera',
            'desc_corta':'Trabajo de porcelanato en proceso simil madera',
            'portada': "/images/trabajos-en-porcelanato/piso-revestido-en-porcelanato-con-patron-hecho-a-mano-cuadriculado.webp",
            'cara': "/images/trabajos-en-porcelanato/piso-con-patron-cuadriculado-en-porcelanato-de-6x6-y-12x6-pulgadas.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Diseño interior en porcelanato',
            'desc_corta':'Trabajo interior con cortes en porcelanato',
            'portada': "/images/trabajos-en-porcelanato/piso-de-oficinas-revestido-en-porcelanato-gris.webp",
            'cara': "/images/trabajos-en-porcelanato/piso-de-oficina-revestido-en-porcelanato-gris.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Porcelanato Atermico Exterior',
            'desc_corta':'Trabajo de porcelanato atermico en exterior',
            'portada': "/images/trabajos-en-porcelanato/piso-revestido-en-porcelanato-simil-madera-oscuro.webp",
            'cara': "/images/trabajos-en-porcelanato/piso-revestido-en-simil-madera-oscuro.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Pisos porcelanato espejo',
            'desc_corta':'Trabajo de porcelanato interior con acabado brillante',
            'portada': "/images/trabajos-en-porcelanato/piso-revestido-en-porcelanto-gris-acabado.webp",
            'cara': "/images/trabajos-en-porcelanato/piso-revestido-en-porcelanto-gris.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Porcelanatos varios',
            'desc_corta':'Trabajos varios en porcelanato con spin niveladores',
            'portada': "/images/trabajos-en-porcelanato/pisos-revestido-en-porcelanato-simil-madera.webp",
            'cara': "/images/trabajos-en-porcelanato/piso-revestido-en-simil-madera.webp",
            'año': '2018',
            'mes': 'OCT',
        }
    ],
    otros: [
        {
            'name': 'Baldosas de Roca',
            'desc_corta':'Trabajos varios en porcelanato con spin niveladores',
            'portada': "/images/trabajos-extras/pisos-recubiertos-en-baldosas-de-roca.webp",
            'cara': "/images/trabajos-extras/pisos-revestidos-en-roca.webp",
            'año': '2018',
            'mes': 'OCT',
        },
        {
            'name': 'Material atermico',
            'desc_corta':'Trabajos varios en porcelanato con spin niveladores',
            'portada': "/images/trabajos-extras/pisos-escaleras-balcones-columnas-de-material-atermico.webp",
            'cara': "/images/trabajos-extras/revestimientos-de-material-atermico.webp",
            'año': '2018',
            'mes': 'OCT',
        }
    ]

};

export default dataMasTrabajos;